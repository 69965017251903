import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import SideBar from "./components/SideBar/SideBar";
import Ethereum from "./components/Ethereum/Ethereum";
import Polygon from "./components/Polygon/Polygon";
import Binance from "./components/Binance/Binance";
import LUKSO from "./components/Lukso/Lukso";
import Avalanche from "./components/Avalanche/Avalanche";
import Optimism from "./components/Optimism/Optimism";
import Mantle from "./components/Mantle/Mantle";
import Arbitrum from "./components/Arbitrum/Arbitrum";
import Home from "./components/Home/Home"; // Importa el componente Home
import Docs from "./components/Docs/Docs";
import Dao from "./components/Dao/Dao";

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "light" ? false : true;
  });

  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (darkMode) {
      bodyElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      bodyElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const appClassName = `App ${darkMode ? "dark" : ""} ${
    sidebarOpen ? "sidebar-open" : "sidebar-closed"
  }`;

  return (
    <Router>
      <div className={appClassName}>
        <SideBar
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          sidebarClosed={sidebarOpen}
          handleSidebarToggle={toggleSidebar}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/ethereum" element={<Ethereum />} />
          <Route path="/polygon" element={<Polygon />} />
          <Route path="/binance" element={<Binance />} />
          <Route path="/lukso" element={<LUKSO />} />
          <Route path="/avalanche" element={<Avalanche />} />
          <Route path="/optimism" element={<Optimism />} />
          <Route path="/mantle" element={<Mantle />} />
          <Route path="/arbitrum" element={<Arbitrum />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/dao" element={<Dao />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
