import React, { useState, useEffect, useMemo } from "react";
import Web3 from "web3";
import { useLocation } from "react-router-dom";
import styles from "./SideBar.module.css";
import SidebarLink from "./SidebarLink";
import { MdOutlineLightMode, MdDarkMode } from "react-icons/md";

// Import PNG icons
import HomeIcon from "../assets/icons/Home.png"; 
import ethereumIcon from "../assets/icons/ethereum.png";
import polygonIcon from "../assets/icons/polygon.png";
import binanceIcon from "../assets/icons/binance.png";
import luksoIcon from "../assets/icons/lukso.png";
import avalancheIcon from "../assets/icons/avalanche.png";
import optimismIcon from "../assets/icons/optimism.png";
import arbitrumIcon from "../assets/icons/arbitrum.png";
import mantleIcon from "../assets/icons/mantle.png"; 

import DaoIcon from  "../assets/icons/Dao.png";
import DocsIcon from "../assets/icons/Docs.png";

const SideBar = ({
  darkMode,
  toggleDarkMode,
  sidebarClosed,
  handleSidebarToggle,
}) => {
  const [account, setAccount] = useState(null);
  const location = useLocation();

  const chains = useMemo(() => ({
    ethereum: {
      chainId: "0x1",
      chainName: "Ethereum",
      nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
      rpcUrls: ["https://mainnet.infura.io/v3/YOUR_INFURA_KEY"],
      blockExplorerUrls: ["https://etherscan.io/"]
    },
    polygon: {
      chainId: "0x89",
      chainName: "Polygon",
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://polygon-rpc.com/"],
      blockExplorerUrls: ["https://polygonscan.com/"]
    },
    binance: {
      chainId: "0x38",
      chainName: "Binance Smart Chain",
      nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.com/"]
    },
    lukso: {
      chainId: "0x2a",
      chainName: "LUKSO",
      nativeCurrency: { name: "LYE", symbol: "LYE", decimals: 18 },
      rpcUrls: ["https://rpc.mainnet.lukso.network"],
      blockExplorerUrls: ["https://explorer.lukso.network/"]
    },
    avalanche: {
      chainId: "0xa86a",
      chainName: "Avalanche",
      nativeCurrency: { name: "AVAX", symbol: "AVAX", decimals: 18 },
      rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      blockExplorerUrls: ["https://snowtrace.io/"]
    },
    optimism: {
      chainId: "0xa",
      chainName: "Optimism",
      nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
      rpcUrls: ["https://mainnet.optimism.io/"],
      blockExplorerUrls: ["https://optimistic.etherscan.io/"]
    },
    arbitrum: {
      chainId: "0xA4B1",
      chainName: "Arbitrum",
      nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
      rpcUrls: ["https://arb1.arbitrum.io/rpc"],
      blockExplorerUrls: ["https://arbiscan.io/"]
    },
    mantle: {
      chainId: "0x10C",
      chainName: "Mantle",
      nativeCurrency: { name: "MNT", symbol: "MNT", decimals: 18 },
      rpcUrls: ["https://rpc.mantlenetwork.io/"],
      blockExplorerUrls: ["https://explorer.mantlenetwork.io/"]
    }
  }), []); 

  useEffect(() => {
    const path = location.pathname.substring(1); 
    const chainKey = path === "" ? "ethereum" : path; 

    if (chains[chainKey]) {
      switchNetwork(chains[chainKey].chainId);
    }
  }, [location, chains]);

  const switchNetwork = async (chainId) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }]
        });
      } catch (switchError) {
        console.error("Error switching network", switchError);
      }
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);

        const path = location.pathname.substring(1);
        const chainKey = path === "" ? "ethereum" : path;
        if (chains[chainKey]) {
          switchNetwork(chains[chainKey].chainId);
        }
      } catch (error) {
        console.error("Error connecting to MetaMask", error);
      }
    } else {
      alert("MetaMask is not installed. Please install it to use this feature.");
    }
  };

  const formatAddress = (address) => {
    return `${address.substring(0, 14)}...${address.substring(address.length - 4)}`;
  };

  return (
    <nav
      className={`${styles.sidebar} ${darkMode ? styles.dark : ""} ${sidebarClosed ? styles.close : ""}`}
    >
      <header>
        <div className={styles.imageText}>
          <span className={styles.image}>
            {darkMode ? (
              <img src="./Logo - Dark.png" alt="logo" />
            ) : (
              <img src="./Logo.png" alt="logo" />
            )}
          </span>
          <div className={`${styles.text} ${styles.headerText}`}>
            <span className={styles.name}>0xUntracked</span>
          </div>
        </div>
      </header>
      <div className={styles.menuBar}>
        <div className={styles.menu}>
          <ul className={styles["menu-links"]}>
            {/* Home goes to the top */}
            <SidebarLink
              icon={<img src={HomeIcon} alt="Home" className={styles.icon} />}
              text={"Home"}
              to={"/"}
            />

            {/* Other blockchain sections */}
            <SidebarLink
              icon={<img src={ethereumIcon} alt="Ethereum" className={styles.icon} />}
              text={"Ethereum"}
              to={"/ethereum"}
              onClick={() => switchNetwork(chains.ethereum.chainId)}
            />
            <SidebarLink
              icon={<img src={polygonIcon} alt="Polygon" className={styles.icon} />}
              text={"Polygon"}
              to={"/polygon"}
              onClick={() => switchNetwork(chains.polygon.chainId)}
            />
            <SidebarLink
              icon={<img src={binanceIcon} alt="Binance" className={styles.icon} />}
              text={"Binance"}
              to={"/binance"}
              onClick={() => switchNetwork(chains.binance.chainId)}
            />
            <SidebarLink
              icon={<img src={luksoIcon} alt="LUKSO" className={styles.icon} />}
              text={"LUKSO"}
              to={"/lukso"}
              onClick={() => switchNetwork(chains.lukso.chainId)}
            />
            <SidebarLink
              icon={<img src={avalancheIcon} alt="Avalanche" className={styles.icon} />}
              text={"Avalanche"}
              to={"/avalanche"}
              onClick={() => switchNetwork(chains.avalanche.chainId)}
            />
            <SidebarLink
              icon={<img src={optimismIcon} alt="Optimism" className={styles.icon} />}
              text={"Optimism"}
              to={"/optimism"}
              onClick={() => switchNetwork(chains.optimism.chainId)}
            />
            <SidebarLink
              icon={<img src={arbitrumIcon} alt="Arbitrum" className={styles.icon} />}
              text={"Arbitrum"}
              to={"/arbitrum"}
              onClick={() => switchNetwork(chains.arbitrum.chainId)}
            />
            <SidebarLink
              icon={<img src={mantleIcon} alt="Mantle" className={styles.icon} />}
              text={"Mantle"}
              to={"/mantle"}
              onClick={() => switchNetwork(chains.mantle.chainId)}
            />
          </ul>
        </div>

        {/* New Sections: Docs and DAO */}
        <div className={styles.menu}>
          <ul className={styles["menu-links"]}>
            <SidebarLink
              icon={<img src={DocsIcon} alt="Docs" className={styles.icon} />}
              text={"Docs"}
              to={"/docs"}
            />
            <SidebarLink
              icon={<img src={DaoIcon} alt="DAO" className={styles.icon} />}
              text={"DAO"}
              to={"/dao"}
            />
          </ul>
        </div>

        {/* Bottom content: Dark Mode toggle and Wallet connection */}
        <div className={styles.bottomContent}>
          <li className={styles.mode}>
            <div className={styles.moonSun}>
              {darkMode ? (
                <i className={`${styles.icon} ${styles.moon}`}>
                  <MdDarkMode />
                </i>
              ) : (
                <i className={`${styles.icon} ${styles.sun}`}>
                  <MdOutlineLightMode />
                </i>
              )}
            </div>
            <span className={`${styles.modeText} ${styles.text}`}>
              {darkMode ? " Light" : " Dark"}
            </span>
            <div
              className={`${styles.toggleSwitch}`}
              onClick={toggleDarkMode}
            >
              <span className={styles.switch}></span>
            </div>
          </li>
          <li>
            <button className={styles.walletButton} onClick={connectWallet}>
              {account ? formatAddress(account) : "Connect Wallet"}
            </button>
          </li>
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
