import React, { useState, useEffect } from "react";
import Web3 from "web3";
import "./Home.css";

// Import user and network icons
import userIcon from "../assets/user.png";
import ethereumIcon from "../assets/icons/ethereum.png";
import polygonIcon from "../assets/icons/polygon.png";
import binanceIcon from "../assets/icons/binance.png";
import luksoIcon from "../assets/icons/lukso.png";
import avalancheIcon from "../assets/icons/avalanche.png";
import optimismIcon from "../assets/icons/optimism.png";
import arbitrumIcon from "../assets/icons/arbitrum.png";
import mantleIcon from "../assets/icons/mantle.png";

const Home = () => {
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [networkName, setNetworkName] = useState("Unknown Network");
  const [networkIcon, setNetworkIcon] = useState(userIcon);
  const [currencySymbol, setCurrencySymbol] = useState("ETH");

  // Web3 connection handler
  const connectWallet = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        setConnected(true);

        // Get balance
        const balanceInWei = await web3.eth.getBalance(accounts[0]);
        const balanceInEth = web3.utils.fromWei(balanceInWei, "ether");
        setBalance(balanceInEth);

        // Get network details using 'eth_chainId'
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });

        handleNetworkChange(chainId);
      } catch (error) {
        console.error("Connection Error", error);
      }
    } else {
      alert("Please install MetaMask to use this feature.");
    }
  };

  // Function to handle network changes
  const handleNetworkChange = (chainId) => {
    let network = "Unknown Network";
    let icon = userIcon;
    let currency = "ETH"; // Default to ETH

    switch (parseInt(chainId, 16)) {
      case 1: // Ethereum Mainnet
        network = "Ethereum";
        icon = ethereumIcon;
        currency = "ETH";
        break;
      case 137: // Polygon Mainnet
        network = "Polygon";
        icon = polygonIcon;
        currency = "POL";
        break;
      case 56: // Binance Smart Chain Mainnet
        network = "Binance Smart Chain";
        icon = binanceIcon;
        currency = "BNB";
        break;
      case 2: // Lukso Mainnet
        network = "LUKSO";
        icon = luksoIcon;
        currency = "LYX";
        break;
      case 43114: // Avalanche Mainnet
        network = "Avalanche";
        icon = avalancheIcon;
        currency = "AVAX";
        break;
      case 10: // Optimism Mainnet
        network = "Optimism";
        icon = optimismIcon;
        currency = "ETH"; // Optimism uses ETH as the native token
        break;
      case 42161: // Arbitrum One Mainnet
        network = "Arbitrum";
        icon = arbitrumIcon;
        currency = "ETH"; // Arbitrum uses ETH as the native token
        break;
      case 5000: // Mantle Mainnet
        network = "Mantle";
        icon = mantleIcon;
        currency = "MNT";
        break;
      default:
        network = "Unknown Network";
        break;
    }

    setNetworkName(network);
    setNetworkIcon(icon);
    setCurrencySymbol(currency);
  };

  useEffect(() => {
    // Handle network changes when user switches networks
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (chainId) => {
        handleNetworkChange(chainId);
      });
    }
  }, []);

  return (
    <div className="home-container">
      <div className="hero-section">
        <h1 className="hero-title">0xUntracked: Your Next-Gen Mixer</h1>
        <p className="hero-subtitle">
          The most secure and fast crypto mixer on the blockchain.
        </p>

        {!connected ? (
          <button className="connect-btn" onClick={connectWallet}>
            Sign in Web3 🦊 
          </button>
        ) : (
          <div className="user-info-panel">
            <div className="user-card">
              <img src={userIcon} alt="User" className="user-img" />
              <h3>Wallet Address</h3>
              <p>
                {account.substring(0, 6)}...{account.substring(account.length - 4)}
              </p>
            </div>

            <div className="user-card">
              <h3>Balance</h3>
              <p>
                {balance} {currencySymbol}
              </p>
            </div>

            <div className="user-card">
              <h3>Connected Network</h3>
              <img
                src={networkIcon}
                alt={networkName}
                className="network-img"
              />
              <p>{networkName}</p>
            </div>
          </div>
        )}

        <div className="features-section">
          <h2>Why Choose 0xUntracked?</h2>
          <div className="feature-cards">
            <div className="feature-card">
              <h3>High-Level Privacy</h3>
              <p>
                Ensure your transactions are completely anonymous using
                cutting-edge cryptography.
              </p>
            </div>
            <div className="feature-card">
              <h3>Blazing Fast Transactions</h3>
              <p>
                Experience unmatched transaction speeds, with funds available in
                under 15 seconds.
              </p>
            </div>
            <div className="feature-card">
              <h3>Cross-Chain Compatibility</h3>
              <p>
                Seamlessly integrate with Ethereum, Binance, Polygon, and more
                for maximum flexibility.
              </p>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <p>Join the future of anonymous transactions. Start using 0xUntracked today!</p>
      </footer>
    </div>
  );
};

export default Home;
