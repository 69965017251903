import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import './ChartsBlockchain.css';

// Importar los ABI necesarios para los contratos de cada blockchain
import polygonAbi from '../abi/polygonAbi.json';
import binanceAbi from '../abi/binanceAbi.json';
import luksoAbi from '../abi/luksoAbi.json'; // ABI de LUKSO
import avalancheAbi from '../abi/avalancheAbi.json';
import optimismAbi from '../abi/optimismAbi.json';
import arbitrumAbi from '../abi/arbitrumAbi.json';
import mantleAbi from '../abi/mantleAbi.json';

// Importar íconos
import ethereumIcon from '../assets/icons/ethereum.png';
import polygonIcon from '../assets/icons/polygon.png';
import binanceIcon from '../assets/icons/binance.png';
import luksoIcon from '../assets/icons/lukso.png';
import avalancheIcon from '../assets/icons/avalanche.png';
import optimismIcon from '../assets/icons/optimism.png';
import mantleIcon from '../assets/icons/mantle.png';
import arbitrumIcon from '../assets/icons/arbitrum.png';

// Función para validar si una dirección de Ethereum es válida
const isValidAddress = (address) => Web3.utils.isAddress(address);

// Función para truncar una dirección
const truncateAddress = (address) => {
  if (!address) return 'No disponible';
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

const ChartsBlockchain = () => {
  const [blockchainData, setBlockchainData] = useState([]);

  // Definir las direcciones de los contratos y símbolos de las monedas para cada blockchain
  const contractAddresses = {
    ethereum: null, // Sin contrato disponible
    polygon: '0x361Af37041BDB28FD375Feb39ea51B304c391d28',
    binance: '0x361Af37041BDB28FD375Feb39ea51B304c391d28',
    lukso: '0x361Af37041BDB28FD375Feb39ea51B304c391d28', // Dirección del contrato en LUKSO
    avalanche: '0x361Af37041BDB28FD375Feb39ea51B304c391d28',
    optimism: '0x361Af37041BDB28FD375Feb39ea51B304c391d28',
    arbitrum: '0x92E762dAB25b759B3dfaa2763e55e42208dAe8A7',
    mantle: '0x361Af37041BDB28FD375Feb39ea51B304c391d28',
  };

  // Definir los símbolos de las monedas para cada blockchain
  const blockchainSymbols = {
    ethereum: 'ETH',
    polygon: 'POL',
    binance: 'BNB',
    lukso: 'LYX',
    avalanche: 'AVAX',
    optimism: 'ETH',
    arbitrum: 'ETH',
    mantle: 'MNT',
  };

  // Definir los nodos RPC para cada blockchain
  const rpcUrls = {
    ethereum: null, // No hay nodo disponible
    polygon: "https://polygon-mainnet.g.alchemy.com/v2/_epLaBGqUQVpDDeIulROEOcMHWsCNvjN",
    binance: "https://bsc-mainnet.infura.io/v3/6f17dc61ae31478b8481da2d8f7daab1",
    lukso: "https://rpc.mainnet.lukso.network", // Nodo RPC de LUKSO
    avalanche: "https://avalanche-mainnet.infura.io/v3/6f17dc61ae31478b8481da2d8f7daab1",
    optimism: "https://opt-mainnet.g.alchemy.com/v2/_epLaBGqUQVpDDeIulROEOcMHWsCNvjN",
    arbitrum: "https://arb-mainnet.g.alchemy.com/v2/_epLaBGqUQVpDDeIulROEOcMHWsCNvjN",
    mantle: "https://mantle-mainnet.infura.io/v3/6f17dc61ae31478b8481da2d8f7daab1",
  };

  const fetchBlockchainData = async () => {
    try {
      const allData = [];

      // Polygon
      if (contractAddresses.polygon && isValidAddress(contractAddresses.polygon)) {
        const web3Polygon = new Web3(new Web3.providers.HttpProvider(rpcUrls.polygon));
        const polygonContract = new web3Polygon.eth.Contract(polygonAbi, contractAddresses.polygon);
        const balance = await polygonContract.methods.poolBalance().call();
        allData.push({
          name: 'Polygon Pool',
          icon: polygonIcon,
          contractAddress: contractAddresses.polygon,
          balance: Web3.utils.fromWei(balance, 'ether'),
          symbol: blockchainSymbols.polygon,
        });
      }

      // Binance
      if (contractAddresses.binance && isValidAddress(contractAddresses.binance)) {
        const web3Binance = new Web3(new Web3.providers.HttpProvider(rpcUrls.binance));
        const binanceContract = new web3Binance.eth.Contract(binanceAbi, contractAddresses.binance);
        const balance = await binanceContract.methods.poolBalance().call();
        allData.push({
          name: 'Binance Pool',
          icon: binanceIcon,
          contractAddress: contractAddresses.binance,
          balance: Web3.utils.fromWei(balance, 'ether'),
          symbol: blockchainSymbols.binance,
        });
      }

      // **LUKSO**
      if (contractAddresses.lukso && isValidAddress(contractAddresses.lukso)) {
        const web3Lukso = new Web3(new Web3.providers.HttpProvider(rpcUrls.lukso));
        const luksoContract = new web3Lukso.eth.Contract(luksoAbi, contractAddresses.lukso); // ABI y dirección de LUKSO
        const balance = await luksoContract.methods.poolBalance().call(); // Método que obtiene el balance del pool
        allData.push({
          name: 'Lukso Pool',
          icon: luksoIcon,
          contractAddress: contractAddresses.lukso,
          balance: Web3.utils.fromWei(balance, 'ether'), // Convierte el saldo a formato legible
          symbol: blockchainSymbols.lukso,
        });
      }

      // Avalanche
      if (contractAddresses.avalanche && isValidAddress(contractAddresses.avalanche)) {
        const web3Avalanche = new Web3(new Web3.providers.HttpProvider(rpcUrls.avalanche));
        const avalancheContract = new web3Avalanche.eth.Contract(avalancheAbi, contractAddresses.avalanche);
        const balance = await avalancheContract.methods.poolBalance().call();
        allData.push({
          name: 'Avalanche Pool',
          icon: avalancheIcon,
          contractAddress: contractAddresses.avalanche,
          balance: Web3.utils.fromWei(balance, 'ether'),
          symbol: blockchainSymbols.avalanche,
        });
      }

      // Optimism
      if (contractAddresses.optimism && isValidAddress(contractAddresses.optimism)) {
        const web3Optimism = new Web3(new Web3.providers.HttpProvider(rpcUrls.optimism));
        const optimismContract = new web3Optimism.eth.Contract(optimismAbi, contractAddresses.optimism);
        const balance = await optimismContract.methods.poolBalance().call();
        allData.push({
          name: 'Optimism Pool',
          icon: optimismIcon,
          contractAddress: contractAddresses.optimism,
          balance: Web3.utils.fromWei(balance, 'ether'),
          symbol: blockchainSymbols.optimism,
        });
      }

      // Arbitrum
      if (contractAddresses.arbitrum && isValidAddress(contractAddresses.arbitrum)) {
        const web3Arbitrum = new Web3(new Web3.providers.HttpProvider(rpcUrls.arbitrum));
        const arbitrumContract = new web3Arbitrum.eth.Contract(arbitrumAbi, contractAddresses.arbitrum);
        const balance = await arbitrumContract.methods.poolBalance().call();
        allData.push({
          name: 'Arbitrum Pool',
          icon: arbitrumIcon,
          contractAddress: contractAddresses.arbitrum,
          balance: Web3.utils.fromWei(balance, 'ether'),
          symbol: blockchainSymbols.arbitrum,
        });
      }

      // Mantle
      if (contractAddresses.mantle && isValidAddress(contractAddresses.mantle)) {
        const web3Mantle = new Web3(new Web3.providers.HttpProvider(rpcUrls.mantle));
        const mantleContract = new web3Mantle.eth.Contract(mantleAbi, contractAddresses.mantle);
        const balance = await mantleContract.methods.poolBalance().call();
        allData.push({
          name: 'Mantle Pool',
          icon: mantleIcon,
          contractAddress: contractAddresses.mantle,
          balance: Web3.utils.fromWei(balance, 'ether'),
          symbol: blockchainSymbols.mantle,
        });
      }

      // Ethereum
      allData.push({
        name: 'Ethereum Pool',
        icon: ethereumIcon,
        contractAddress: 'No disponible',
        balance: 'Loading...', // Mostrar "Loading" hasta que tengamos datos reales
        symbol: blockchainSymbols.ethereum,
      });

      setBlockchainData(allData);
    } catch (error) {
      console.error('Error interactuando con los contratos:', error);
    }
  };

  useEffect(() => {
    fetchBlockchainData();
  }, []);

  // Simulación de porcentaje para la barra de progreso
  const getProgress = (balance) => {
    if (balance === 'Loading...') return 0; // Mostrar 0% para estados de "Loading"
    const maxBalance = 5; // Valor máximo simulado
    return (balance / maxBalance) * 100; // Cálculo del porcentaje
  };

  return (
    <div className="charts-container">
      {blockchainData.map((blockchain, index) => (
        <div key={`${index}-balance`} className="card">
          <div className="card-header">
            <img src={blockchain.icon} alt={`${blockchain.name} icon`} className="blockchain-icon" />
            <h3>{blockchain.name}</h3>
          </div>
          <p>Dirección del contrato: {truncateAddress(blockchain.contractAddress)}</p>
          <p>Balance: {blockchain.balance} {blockchain.symbol}</p>
          {/* Barra de progreso */}
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${getProgress(blockchain.balance)}%` }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChartsBlockchain;
