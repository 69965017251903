import React from "react";
import "./Dao.css";

const Dao = () => {
  // Ejemplos de propuestas iniciales aprobadas
  const proposals = [
    {
      id: 1,
      title: "Launch on Selected Blockchains",
      description: "Deploy the 0xUntracked mixer on Ethereum, Binance Smart Chain, Polygon, Avalanche, and Optimism.",
      votes: {
        for: 1200,
        against: 100,
        abstain: 50,
      },
      status: "Approved",
    },
    {
      id: 2,
      title: "Set Transaction Fee at 5%",
      description: "Implement a fixed 5% transaction fee on all mixer transactions to support the development and operations.",
      votes: {
        for: 1100,
        against: 300,
        abstain: 80,
      },
      status: "Approved",
    },
    {
      id: 3,
      title: "Introduce Governance Token (0xUntracked)",
      description: "Create and issue the 0xUntracked governance token for DAO participation and future voting.",
      votes: {
        for: 1500,
        against: 200,
        abstain: 30,
      },
      status: "Approved",
    },
  ];

  return (
    <div className="dao-container">
      <h1>DAO Governance</h1>
      <p>Welcome to the 0xUntracked DAO Governance. Here you can view past proposals and participate in future decision-making processes.</p>

      {/* Display existing proposals */}
      <div className="proposals-section">
        <h2>Proposals</h2>
        {proposals.map((proposal) => (
          <div key={proposal.id} className="proposal-card">
            <h3>{proposal.title}</h3>
            <p>{proposal.description}</p>
            <div className="proposal-stats">
              <p><strong>Votes For:</strong> {proposal.votes.for}</p>
              <p><strong>Votes Against:</strong> {proposal.votes.against}</p>
              <p><strong>Abstentions:</strong> {proposal.votes.abstain}</p>
              <p><strong>Status:</strong> {proposal.status}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Section to create new proposals */}
      <div className="create-proposal-section">
        <h2>Create a New Proposal</h2>
        <button className="create-proposal-btn" onClick={() => alert('Coming soon! Governance token required.')}>
          Propose a New Idea
        </button>
      </div>
    </div>
  );
};

export default Dao;
