import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import './CryptoWidgets.css';

// Import icon images
import ethereumIcon from '../assets/icons/ethereum.png';
import polygonIcon from '../assets/icons/polygon.png';
import binanceIcon from '../assets/icons/binance.png';
import luksoIcon from '../assets/icons/lukso.png';
import avalancheIcon from '../assets/icons/avalanche.png';
import optimismIcon from '../assets/icons/optimism.png';
import mantleIcon from '../assets/icons/mantle.png'; // Asegúrate de tener este ícono
import arbitrumIcon from '../assets/icons/arbitrum.png'; // Asegúrate de tener este ícono

// Binance and CoinGecko APIs
const BINANCE_BASE_URL = "https://api.binance.com/api/v3/klines";
const COINGECKO_BASE_URL = "https://api.coingecko.com/api/v3/coins";

// Fetch chart data from APIs
const fetchChartData = async () => {
  try {
    const [ethResponse, polygonResponse, binanceResponse, luksoResponse, avalancheResponse, optimismResponse, mantleResponse, arbitrumResponse] = await Promise.all([
      axios.get(`${BINANCE_BASE_URL}?symbol=ETHUSDT&interval=1d&limit=30`), // Ethereum from Binance
      axios.get(`${BINANCE_BASE_URL}?symbol=MATICUSDT&interval=1d&limit=30`), // Polygon from Binance
      axios.get(`${BINANCE_BASE_URL}?symbol=BNBUSDT&interval=1d&limit=30`),  // Binance Coin from Binance
      axios.get(`${COINGECKO_BASE_URL}/lukso-token/market_chart?vs_currency=usd&days=30`), // Lukso from CoinGecko
      axios.get(`${BINANCE_BASE_URL}?symbol=AVAXUSDT&interval=1d&limit=30`), // Avalanche from Binance
      axios.get(`${BINANCE_BASE_URL}?symbol=OPUSDT&interval=1d&limit=30`),    // Optimism from Binance
      axios.get(`${COINGECKO_BASE_URL}/mantle/market_chart?vs_currency=usd&days=30`), // Mantle from CoinGecko
      axios.get(`${COINGECKO_BASE_URL}/arbitrum/market_chart?vs_currency=usd&days=30`)   // Arbitrum from CoinGecko
    ]);

    return {
      ethereum: ethResponse.data,
      polygon: polygonResponse.data,
      binance: binanceResponse.data,
      lukso: luksoResponse.data.prices ? luksoResponse.data.prices : [],  // Handle nested data in CoinGecko
      avalanche: avalancheResponse.data,
      optimism: optimismResponse.data,
      mantle: mantleResponse.data.prices ? mantleResponse.data.prices : [], // Handle nested data in CoinGecko
      arbitrum: arbitrumResponse.data.prices ? arbitrumResponse.data.prices : [] // Handle nested data in CoinGecko
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {
      ethereum: [],
      polygon: [],
      binance: [],
      lukso: [],
      avalanche: [],
      optimism: [],
      mantle: [],
      arbitrum: []
    };
  }
};

// Function to draw D3 trend chart
const drawTrendChart = (data, svg) => {
  if (!data || data.length === 0) {
    console.error('No data available for chart.');
    return;
  }

  const margin = { top: 20, right: 20, bottom: 30, left: 50 };
  const width = 300 - margin.left - margin.right;
  const height = 100 - margin.top - margin.bottom;

  const x = d3.scaleTime().range([0, width]);
  const y = d3.scaleLinear().range([height, 0]);

  const line = d3.line()
    .x(d => x(d.date))
    .y(d => y(d.price));

  const svgElement = d3.select(svg)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Format data
  data.forEach(d => {
    d.date = new Date(d[0]);
    d.price = d[1];
  });

  x.domain(d3.extent(data, d => d.date));
  y.domain([0, d3.max(data, d => d.price)]);

  svgElement.append('path')
    .data([data])
    .attr('class', 'line')
    .attr('d', line);

  svgElement.append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).ticks(5));

  svgElement.append('g')
    .attr('class', 'y axis')
    .call(d3.axisLeft(y).ticks(5));
};

const CryptoWidgets = () => {
  const [cryptoData, setCryptoData] = useState({
    ethereum: [],
    polygon: [],
    binance: [],
    lukso: [],
    avalanche: [],
    optimism: [],
    mantle: [],
    arbitrum: []
  });

  const [amount, setAmount] = useState(1); // Amount for fee calculation

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchChartData();
      setCryptoData(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const svgElements = document.querySelectorAll('.crypto-trend svg');
    svgElements.forEach((svg, index) => {
      const data = Object.values(cryptoData)[index];
      if (data && data.length > 0) {
        drawTrendChart(data, svg);
      }
    });
  }, [cryptoData]);

  // Fee calculation logic
  const calculateFee = (deposit) => (deposit * 0.95).toFixed(4);

  return (
    <div className="crypto-widgets-container">
      <div className="crypto-widgets-grid">
        {Object.keys(cryptoData).map(key => (
          <div key={key} className="crypto-widget-card">
            <img
              src={
                key === 'ethereum' ? ethereumIcon :
                key === 'polygon' ? polygonIcon :
                key === 'binance' ? binanceIcon :
                key === 'lukso' ? luksoIcon :
                key === 'avalanche' ? avalancheIcon :
                key === 'optimism' ? optimismIcon :
                key === 'mantle' ? mantleIcon :
                arbitrumIcon
              }
              alt={`${key} icon`}
              className="crypto-icon"
            />
            <h2>{key.charAt(0).toUpperCase() + key.slice(1)}</h2>
            <div className="crypto-price">
              {cryptoData[key]?.length > 0 ? `$${cryptoData[key][cryptoData[key].length - 1][1]}` : 'Loading...'}
            </div>
            <div className="crypto-trend">
              <svg></svg>
            </div>
            <div className="fee-calculator">
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                min="0"
                placeholder="Amount to deposit"
              />
              <p>Receive after fee: {calculateFee(amount)} {key.toUpperCase()}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CryptoWidgets;
