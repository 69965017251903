import React, { useState, useEffect } from "react";
import './Ethereum.css';
import Web3 from "web3";
import CryptoWidgets from '../CryptoWidgets/CryptoWidgets';
import ChartsBlockchain from '../ChartsBlockchain/ChartsBlockchain';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import ethereumAbi from '../abi/ethereumAbi.json'; // Asegúrate de tener el ABI correspondiente

const Ethereum = () => {
  const [commitment, setCommitment] = useState('');
  const [secret, setSecret] = useState('');
  const [nullifier, setNullifier] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawSecret, setWithdrawSecret] = useState('');
  const [withdrawNullifier, setWithdrawNullifier] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  
  const [depositTransactionHash, setDepositTransactionHash] = useState('');
  const [withdrawTransactionHash, setWithdrawTransactionHash] = useState('');
  
  const [depositErrorMessage, setDepositErrorMessage] = useState('');
  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState('');
  
  const [showPopup, setShowPopup] = useState(false);
  const [remainingDeposits, setRemainingDeposits] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [depositDetails, setDepositDetails] = useState(null);

  const contractAddress = "0x361Af37041BDB28FD375Feb39ea51B304c391d28"; // Dirección del contrato en Ethereum

  // Generar secret, nullifier y commitment
  const generateSecretAndCommitment = () => {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");

    const secretBytes = web3.utils.randomHex(32);
    const nullifierBytes = web3.utils.randomHex(32);

    const generatedCommitment = web3.utils.keccak256(
      web3.eth.abi.encodeParameters(["bytes32", "bytes32"], [secretBytes, nullifierBytes])
    );

    setSecret(secretBytes);
    setNullifier(nullifierBytes);
    setCommitment(generatedCommitment);
    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  // Función para manejar el depósito
  const handleDeposit = async () => {
    if (!commitment || !depositAmount) {
      setDepositErrorMessage('Please enter the commitment and amount.');
      return;
    }
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();

      const contract = new web3.eth.Contract(ethereumAbi, contractAddress);

      const transaction = await contract.methods.deposit(commitment).send({
        from: accounts[0],
        value: web3.utils.toWei(depositAmount, "ether")
      });

      setDepositTransactionHash(transaction.transactionHash);
      setDepositErrorMessage('');
    } catch (error) {
      console.error(error);
      setDepositErrorMessage("Error making deposit. " + error.message);
    }
  };

  // Función para manejar el retiro
  const handleWithdraw = async () => {
    if (!withdrawSecret || !withdrawNullifier || !recipientAddress || !withdrawAmount) {
      setWithdrawErrorMessage('Please enter all fields: secret, nullifier, amount, and recipient address.');
      return;
    }
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();

      const contract = new web3.eth.Contract(ethereumAbi, contractAddress);

      const transaction = await contract.methods.withdraw(
        withdrawSecret,
        withdrawNullifier,
        web3.utils.toWei(withdrawAmount, "ether"),
        recipientAddress
      ).send({
        from: accounts[0]
      });

      setWithdrawTransactionHash(transaction.transactionHash);
      setWithdrawErrorMessage('');
    } catch (error) {
      console.error(error);
      setWithdrawErrorMessage("Error al realizar el retiro. " + error.message);
    }
  };

  // Función para obtener los depósitos restantes y el tiempo restante
  const getRemainingDeposits = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(ethereumAbi, contractAddress);

      const remaining = await contract.methods.getRemainingDeposits(commitment).call();
      setRemainingDeposits(Number(remaining)); 

      const depositData = await contract.methods.commitments(commitment).call();
      const currentTime = Math.floor(Date.now() / 1000);
      const timePassed = currentTime - Number(depositData.depositTime); 
      const timeLeft = Math.max(3600 - timePassed, 0);
      setTimeRemaining(timeLeft);

    } catch (error) {
      console.error(error);
      setDepositErrorMessage("You have no active deposits with this Commitment code. " + error.message);
    }
  };

  const formatHash = (hash) => {
    if (!hash) return '';
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 6)}`;
  };

  // Función para verificar los detalles del depósito
  const checkCommitmentDetails = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(ethereumAbi, contractAddress);
      const depositData = await contract.methods.commitments(commitment).call();

      const amountDeposited = web3.utils.fromWei(depositData.amount.toString(), 'ether');
      const amountWithdrawn = web3.utils.fromWei(depositData.withdrawn.toString(), 'ether');
      const remainingToWithdraw = parseFloat(amountDeposited) - parseFloat(amountWithdrawn);

      setDepositDetails({ amountDeposited, amountWithdrawn, remainingToWithdraw });
    } catch (error) {
      console.error(error);
      setDepositErrorMessage("Error fetching commitment details. " + error.message);
    }
  };

  return (
    <div>
      <CryptoWidgets cryptoType="Ethereum" />
      <ChartsBlockchain />

      {showPopup && <div className="popup-success">Codes generated successfully!</div>}

      <div className="generate-codes-container">
        <div className="generate-secret-card">
          <h3>Generate Secret Code</h3>

          {secret && (
            <div className="code-display">
              <span>Secret:</span>
              <span>{secret}</span>
              <CopyToClipboard text={secret}>
                <button className="copy-button">
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          )}

          {nullifier && (
            <div className="code-display">
              <span>Nullifier:</span>
              <span>{nullifier}</span>
              <CopyToClipboard text={nullifier}>
                <button className="copy-button">
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          )}

          {commitment && (
            <div className="code-display">
              <span>Commitment:</span>
              <span>{commitment}</span>
              <CopyToClipboard text={commitment}>
                <button className="copy-button">
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          )}

          <button className="generate-secret-button" onClick={generateSecretAndCommitment}>Generate Off-Chain Codes</button>
        </div>

        <div className="other-cards-container">
          <div className="card deposit-card">
            <h3>Deposit in Ethereum</h3>
            <input
              type="text"
              placeholder="Commitment (bytes32)"
              value={commitment}
              onChange={(e) => setCommitment(e.target.value)}
            />
            <input
              type="number"
              placeholder="Amount in ETH"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
            />
            <button onClick={handleDeposit}>Deposit</button>

            {depositTransactionHash && (
              <p className="success-message">
                Transaction completed. Hash: {formatHash(depositTransactionHash)}
                <CopyToClipboard text={depositTransactionHash}>
                  <button className="copy-hash-button">
                    <FaCopy />
                  </button>
                </CopyToClipboard>
              </p>
            )}

            {depositErrorMessage && <p className="error">{depositErrorMessage}</p>}
          </div>

          <div className="card withdraw-card">
            <h3>Withdraw Funds</h3>
            <input
              type="text"
              placeholder="Secret (bytes32)"
              value={withdrawSecret}
              onChange={(e) => setWithdrawSecret(e.target.value)}
            />
            <input
              type="text"
              placeholder="Nullifier (bytes32)"
              value={withdrawNullifier}
              onChange={(e) => setWithdrawNullifier(e.target.value)}
            />
            <input
              type="number"
              placeholder="Amount to withdraw (in ETH)"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
            />
            <input
              type="text"
              placeholder="Address"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
            />
            <button onClick={handleWithdraw}>Withdraw</button>

            {withdrawTransactionHash && (
              <p className="success-message">
                Transaction completed. Hash: {formatHash(withdrawTransactionHash)}
                <CopyToClipboard text={withdrawTransactionHash}>
                  <button className="copy-hash-button">
                    <FaCopy />
                  </button>
                </CopyToClipboard>
              </p>
            )}

            {withdrawErrorMessage && <p className="error">{withdrawErrorMessage}</p>}
          </div>

          <div className="card remaining-deposits-card">
            <h3>Remaining Deposits for Withdrawal</h3>
            <input
              type="text"
              placeholder="Commitment (bytes32)"
              value={commitment}
              onChange={(e) => setCommitment(e.target.value)}
            />
            <button onClick={getRemainingDeposits}>View Remaining Deposits</button>
            {remainingDeposits !== null && <p>Remaining Deposits: {remainingDeposits}</p>}
            {timeRemaining !== null && <p>Time left to withdraw: {timeRemaining} seconds</p>}
            {depositErrorMessage && <p className="error">{depositErrorMessage}</p>}
          </div>

          <div className="card nullifier-card">
            <h3>Check Commitment</h3>
            <input
              type="text"
              placeholder="Commitment (bytes32)"
              value={commitment}
              onChange={(e) => setCommitment(e.target.value)}
            />
            <button onClick={checkCommitmentDetails}>Check Commitment</button>
            {depositDetails && (
              <div>
                <p>Amount Deposited: {depositDetails.amountDeposited} ETH</p>
                <p>Amount Withdrawn: {depositDetails.amountWithdrawn} ETH</p>
                <p>Remaining to Withdraw: {depositDetails.remainingToWithdraw} ETH</p>
              </div>
            )}
            {depositErrorMessage && <p className="error">{depositErrorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ethereum;
