import React from 'react';
import './Docs.css';

const Docs = () => {
  return (
    <div className="new-home-container">
      <h1>./0xUntracked Mixer</h1>

      {/* Botones sociales */}
      <div className="new-social-buttons">
        <a href="https://github.com" className="new-social-button">
          <i className="bi bi-github"></i>
        </a>
        <a href="https://twitter.com" className="new-social-button">
          <i className="bi bi-twitter-x"></i>
        </a>
        <a href="/whitepaper" className="new-social-button">
          <i className="bi bi-book"></i>
        </a>
      </div>

      {/* Sección de explicación técnica */}
      <div className="new-explanation-section">
        <h2>Instructions for use</h2>
        <div className="new-code-style">
          <p><span className="new-code-command">1.</span> The user connects their wallet using Metamask.</p>
          <p><span className="new-code-command">2.</span> The user generates three codes using the dApp: <span className="new-code-text">Commitment</span>, <span className="new-code-text">Secret</span>, and <span className="new-code-text">Nullifier</span>. These codes are cryptographically linked and generated off-chain, ensuring no one else has access to them. The <span className="new-code-text">Commitment</span> is the only one exposed on-chain.</p>
          <p><span className="new-code-command">3.</span> The user deposits native blockchain currency using the <span className="new-code-text">Commitment</span>. Their deposit gets mixed with other deposits. The <span className="new-code-text">Commitment</span> is stored on-chain and associated with the deposit wallet, while the <span className="new-code-text">Secret</span> and <span className="new-code-text">Nullifier</span> remain private.</p>
          <p><span className="new-code-command">4.</span> To withdraw funds, the user changes wallet addresses and uses the <span className="new-code-text">Secret</span> and <span className="new-code-text">Nullifier</span> to withdraw the funds. The withdrawal must be sent to a third wallet, ensuring that the link between the deposit <span className="new-code-text">Commitment</span> and the destination wallet is completely severed.</p>
          <p><span className="new-code-command">Notes:</span> The user can withdraw multiple times as long as the total does not exceed the deposit amount. For example, if they deposited 1 ETH, they can withdraw 0.50 ETH, 0.25 ETH, and 0.20 ETH (with a 5% protocol fee). Withdrawals can be made from different accounts, ensuring anonymity.</p>
          <p><span className="new-code-command">-</span> The user must wait for an additional 3 to 7 deposits and a 1-hour waiting period to withdraw, ensuring the pool's anonymity.</p>
          <p><span className="new-code-command">-</span> The user can check how many additional deposits are needed before withdrawing and track their total deposits and withdrawals at any time.</p>
    
        </div>
      </div>

      {/* Sección de preguntas frecuentes */}
      <div className="new-faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="new-faq-container">
          <details className="new-faq-item">
            <summary>What is a mixer and how does it work?</summary>
            <p>A mixer allows you to anonymize your cryptocurrency transactions by pooling your deposits with others, making it difficult to trace the origin of funds.</p>
          </details>
          <details className="new-faq-item">
            <summary>What happens if I lose my secret?</summary>
            <p>If you lose your secret or nullifier, you will not be able to withdraw your funds. Store them securely.</p>
          </details>
          <details className="new-faq-item">
            <summary>What is the Commitment system?</summary>
            <p>The Commitment system is a cryptographic method used to anonymize transactions. Only the Commitment is stored on-chain, while the Secret and Nullifier remain private.</p>
          </details>
          <details className="new-faq-item">
            <summary>How are the codes generated and used?</summary>
            <p>Codes are generated off-chain using cryptography. The Commitment is exposed on-chain, while the Secret and Nullifier are used privately for withdrawals.</p>
          </details>
          <details className="new-faq-item">
            <summary>Are these codes reversible?</summary>
            <p>No, these cryptographic codes cannot be reversed, even with advanced algorithms.</p>
          </details>
          <details className="new-faq-item">
            <summary>Can I withdraw funds multiple times?</summary>
            <p>Yes, users can withdraw in multiple transactions, but the total must not exceed the deposit amount. The protocol fee is 5%.</p>
          </details>
          <details className="new-faq-item">
            <summary>How does the mixer ensure anonymity?</summary>
            <p>The mixer pools deposits, making it impossible to track the origin of funds. Users must wait for additional deposits and a set time to withdraw.</p>
          </details>
          <details className="new-faq-item">
            <summary>What is the purpose of the waiting period?</summary>
            <p>The waiting period ensures anonymity by allowing multiple deposits to mix together before withdrawals can be made.</p>
          </details>
        </div>
      </div>
    </div>
  );
};

export default Docs;
